import classNames from 'classnames';
import React from 'react';
import { RoutePaths } from '../../../../routes';
import { ContentWrapper } from '../../../content_wrapper/content_wrapper';
import logo from '../../../header/logo.svg';
import { OmniaLinkButton } from '../../../omnia_button/omnia_link_button';
import styles from './hero_logged_out_v1.less';

export const HeroLoggedOutV1 = () => {
  return (
    <section className={styles.hero}>
      <section className={styles.bg}>
        <ContentWrapper>
          <div className={styles.top}>
            <h1 className={styles.h1}>Hit the Water with the Perfect Plan and Tackle</h1>
            <div className={styles.shiny}>
              <img src={logo} width={39} height={9} alt="Omnia Fishing" /> Intelligent Shopping
            </div>
          </div>
        </ContentWrapper>
      </section>
      <ContentWrapper>
        <div className={styles.shopBoxes}>
          <div className={classNames(styles.shop, styles.shop__lake)}>
            <h2 className={styles.h2}>Search, Plan, & Shop Layered Fishing Maps for Your Lake</h2>
            <OmniaLinkButton to={RoutePaths.MAP} kind="primary" size="lg">
              Shop Your Lake
            </OmniaLinkButton>
          </div>
          <div className={classNames(styles.shop, styles.shop__tackle)}>
            <h2 className={styles.h2}>
              Shop Trending Brands & Tackle Near You with Select Local Reports
            </h2>
            <OmniaLinkButton to={RoutePaths.SHOP} kind="primary" size="lg">
              Shop Tackle
            </OmniaLinkButton>
          </div>
        </div>
      </ContentWrapper>
    </section>
  );
};
