import React from 'react';
import { useExperiment } from 'statsig-react';
import { useQueryString } from '../../hooks/use_query_string';
import { HeroLoggedOutV1 } from './heroes/v1/hero_logged_out_v1';
import { SearchHero } from './heroes/search_hero/search_hero';

export const HeroLoggedOut = () => {
  const { currentQuery } = useQueryString();

  const defaultVersion = (currentQuery.home_search_vs_split_ctas as 'v1' | 'v2') || 'v2';
  const heroVersion = useExperiment('home_search_vs_split_ctas').config.get<'v1' | 'v2'>(
    'version',
    defaultVersion
  );

  return (
    <>
      {heroVersion === 'v1' && <HeroLoggedOutV1 />}
      {heroVersion === 'v2' && <SearchHero />}
    </>
  );
};
